import { useState } from 'react'
import './support.scss'
import Footer from '../../components/Footer/Footer.js'
import Nav from '../../components/Nav/Nav'
import { typeOptions } from 'utils/constants'
import { useLocation } from 'react-router-dom'

export default function Support() {
    const location = useLocation()
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        contract: '',
        description: '',
        subType: '', // Changed from 'type' to 'subType'
        // Attachment fields matching Salesforce requirements
        attachmentName: '', // For the filename
        attachmentFileString1: '', // First part of base64 data
        attachmentFileString2: '', // Second part of base64 data (if needed)
    })

    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('')

    const fieldChange = ({ name, value }) => {
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    // Function to handle file conversion and splitting
    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0]
        if (selectedFile) {
            setFile(selectedFile)
            setFileName(selectedFile.name)

            try {
                // Convert file to base64
                const base64String = await new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = () => {
                        // Get only the base64 data part, removing the data URL prefix
                        const base64Data = reader.result.split(',')[1]
                        resolve(base64Data)
                    }
                    reader.onerror = reject
                    reader.readAsDataURL(selectedFile)
                })

                // Split the base64 string if it's longer than 32,768 characters
                const maxLength = 32768
                setFormValues((prev) => ({
                    ...prev,
                    attachmentName: selectedFile.name,
                    attachmentFileString1: base64String.substring(0, maxLength),
                    attachmentFileString2:
                        base64String.length > maxLength
                            ? base64String.substring(maxLength)
                            : '',
                }))
            } catch (error) {
                console.error('Error processing file:', error)
                // You might want to show an error message to the user here
            }
        }
    }

    const formatDescription = () => {
        // Formatting description with blank line separation as required
        return `Full Name: ${formValues.name}

Contract Number: ${formValues.contract}

Issue Description: ${formValues.description}`
    }

    const validateSupportForm = (e) => {
        // Update description with formatted text first, as we'll need it in both modes
        const updatedDescription = formatDescription()

        setFormValues((prev) => ({
            ...prev,
            description: updatedDescription,
        }))

        // Helper function to check if we're in development/localhost
        const isDevelopment = () => {
            // Check if we're running on localhost
            return (
                window.location.hostname === 'localhost' ||
                window.location.hostname === '127.0.0.1'
            )
        }

        // If in dev env, running simple test
        if (isDevelopment()) {
            e.preventDefault() // Prevent form submission in development

            // Data object with expected data
            const submissionData = {
                // Standard form fields
                name: formValues.name,
                email: formValues.email,
                description: updatedDescription,

                // Hidden fields
                orgid: process.env.REACT_APP_SF_ORG_ID,
                type: 'Access Issue',
                origin: 'Web',
                TBD: 'frontporch',

                // Mapped fields
                subType: formValues.subType,

                // Attachment fields
                attachmentName: formValues.attachmentName,
                attachmentFileString1: formValues.attachmentFileString1,
                attachmentFileString2__c: formValues.attachmentFileString2,

                // Other required fields
                formOrigin: 'front porch',
                external: '1',
                recordType: '012Hs000001AI9EIAW',
                retURL: 'https://seeker.warnerbros.com/support?success',
            }

            // Stylizing the console output for clarity and because it's pretty.
            console.group('📝 Form Submission Test Data')
            console.log(
                '%c🎯 Form Target URL:',
                'font-weight: bold; color:rgb(255, 255, 255);',
                process.env.REACT_APP_SF_CASE_FORM_HREF
            )
            console.log(
                '%c📊 Submission Data:',
                'font-weight: bold; color: #059669;',
                submissionData
            )

            // Log file attachment details if present
            if (formValues.attachmentName) {
                console.group('📎 File Attachment Details')
                console.log('📄 File Name:', formValues.attachmentName)
                console.log(
                    '📏 AttachmentFileString1 Length:',
                    formValues.attachmentFileString1.length
                )
                console.log(
                    '📏 AttachmentFileString2 Length:',
                    formValues.attachmentFileString2.length
                )
                console.log(
                    '📊 Total File Data Length:',
                    formValues.attachmentFileString1.length +
                        formValues.attachmentFileString2.length
                )
                console.groupEnd()
            }

            console.groupEnd()

            // For future self
            alert('Development Mode: Form data has been logged to console.')

            return
        }

        // Production logic - only runs if we're not in development
        const errors = Object.keys(formValues).filter(
            (item) => formValues[item].length < 1
        )

        if (errors.length > 0) {
            e.preventDefault()
            // You might want to add error handling here for production
        }
        // If no errors, the form will submit naturally
    }

    return (
        <div className="w-full mx-auto support standard-page max-w-8xl">
            <header className="relative grid h-32 px-10 py-2 text-white sm:h-20 sm:py-6 sm:px-18 ">
                <Nav />
            </header>

            <main className="min-h-page">
                {location.search.indexOf('success') > -1 ? (
                    <aside>
                        <h3 className="text-4xl my-9">Thank you</h3>

                        <p>
                            Your support request has been submitted, and a
                            member of the Seeker Support team will reach out to
                            you.{' '}
                        </p>
                    </aside>
                ) : (
                    <div>
                        <aside>
                            <h3 className="text-4xl my-9">Support</h3>
                            <p className="my-4">
                                Having trouble logging in? Please contact us for
                                help
                            </p>
                        </aside>

                        <form
                            action={process.env.REACT_APP_SF_CASE_FORM_HREF}
                            method="POST"
                            onSubmit={(e) => validateSupportForm(e)}
                            className="max-w-lg space-y-4 text-sm font-arial"
                        >
                            <input
                                required
                                type="hidden"
                                name="orgid"
                                value={process.env.REACT_APP_SF_ORG_ID}
                            />
                            <input
                                required
                                type="hidden"
                                name="retURL"
                                value="https://seeker.warnerbros.com/support?success"
                            />
                            <input
                                required
                                type="hidden"
                                id="formOrigin"
                                name="formOrigin"
                                value="front porch"
                            />
                            <input
                                required
                                type="hidden"
                                id="external"
                                name="external"
                                value="1"
                            />
                            <input
                                type="hidden"
                                name="recordType"
                                id="recordType"
                                value="012Hs000001AI9EIAW"
                            />

                            {/* Required TBD field - FRAN 891 */}
                            <input
                                type="hidden"
                                id="TBD"
                                name="TBD"
                                required
                                value="frontporch"
                            />

                            {/* New hidden fields for Salesforce case requirements - FRAN 891 */}
                            <input
                                type="hidden"
                                name="type"
                                value="Access Issue"
                            />
                            <input type="hidden" name="origin" value="Web" />

                            {/* File attachment hidden fields - FRAN 891 */}
                            <input
                                type="hidden"
                                name="attachment_name"
                                value={formValues.attachmentName}
                            />
                            <input
                                type="hidden"
                                name="attachment_file_string1"
                                value={formValues.attachmentFileString1}
                            />
                            <input
                                type="hidden"
                                name="attachment_file_string2__c"
                                value={formValues.attachmentFileString2}
                            />

                            <fieldset>
                                <label htmlFor="name">Full Name</label>
                                <input
                                    required
                                    id="name"
                                    maxLength="80"
                                    name="name"
                                    size="20"
                                    type="text"
                                    value={formValues.name}
                                    className="text-black py-3 px-1.5"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                />
                            </fieldset>
                            <fieldset>
                                <label htmlFor="email">E-mail</label>
                                <input
                                    required
                                    id="email"
                                    maxLength="80"
                                    name="email"
                                    size="20"
                                    type="text"
                                    value={formValues.email}
                                    className="text-black py-3 px-1.5"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                />
                            </fieldset>

                            <fieldset>
                                <label htmlFor="contract">
                                    Contract Number
                                </label>
                                <span className="text-xs helptext">
                                    A contract number is required to locate your
                                    account information
                                </span>
                                <input
                                    required
                                    name="contract"
                                    type="text"
                                    value={formValues.contract}
                                    className="text-black py-3 px-1.5"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                />
                            </fieldset>

                            {/* Updated this to use subType as suggested by FRAN 891. Introduced new hidden field named type above. */}
                            <fieldset>
                                <label htmlFor="subType">
                                    Service You're Trying to Access
                                </label>
                                <select
                                    required
                                    id="subType"
                                    name="subType"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                    value={formValues.subType}
                                    className="text-black py-3 px-1.5"
                                >
                                    {typeOptions.map(({ label, value }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </fieldset>

                            <fieldset>
                                <label htmlFor="description">
                                    Describe Your Login Issue
                                </label>
                                <textarea
                                    required
                                    rows="4"
                                    name="description"
                                    value={formValues.description}
                                    className="text-black py-3 px-1.5"
                                    onChange={({ target }) =>
                                        fieldChange(target)
                                    }
                                ></textarea>
                            </fieldset>

                            <fieldset>
                                <label htmlFor="file">Upload Attachment</label>
                                <input
                                    id="file"
                                    type="file"
                                    name="file"
                                    accept=".jpg,.jpeg,.png,.pdf"
                                    onChange={handleFileChange}
                                    className="text-black py-3 px-1.5"
                                />
                                {fileName && (
                                    <p className="text-sm mt-2">
                                        Selected file: {fileName}
                                    </p>
                                )}{' '}
                                {/* Display filename */}
                            </fieldset>

                            <fieldset>
                                <input
                                    className="py-3 mx-auto mt-6 border-2 border-slate-700 btn btn-style px-9"
                                    type="submit"
                                    name="submit"
                                />
                            </fieldset>
                        </form>
                    </div>
                )}
            </main>
            <Footer />
        </div>
    )
}
